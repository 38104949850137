import React from "react"
import Page from "../components/Page"
import Layout from "../components/Layout"
import useStoryblok from "../utils/storyblok"
import Content404Page from "../components/content404Page"

export default function Page404({ location }) {
  const story = useStoryblok(null, location)

  let content = (
    <>
      <Content404Page />{" "}
    </>
  )
  if (story && story.content) content = <Page blok={story.content} />

  return <Layout location={location}>{content}</Layout>
}
