import * as React from "react";
import img1 from '../images/404Page/error-404-page.png'


const Content404Page = ({ blok }) => (
    <section className="font-koh py-20 bg-white_100">
		<div className="container pt-20 pb-20">
			<div className="sm:w-8/12 mx-auto text-center mt-12 mb-8">
                <img id="successImage" className="mx-auto w-20 mb-4" src= {img1} alt="confirmation"/>                
				<h2 className="text-black leading-none mb-4">Oops... nothing to see here!</h2>
				<p className="font-normal textr-lg leading-snug text-black">
				Looks like the page you are looking for is not available or no longer exists!
				</p>
			</div>
			<div className="text-center pb-20">
                    <a id="no-underline" href="/"
                        className="text-gold_300 py-0 px-2 font-bold hover:text-gold_200 transition-colors duration-300">
                        Back to the Home page
                    </a>
			</div>
		</div>
	</section>
    )

export default Content404Page 